"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./assertEnvironment");
var reactBatchedUpdates_1 = require("./utils/reactBatchedUpdates");
var observerBatching_1 = require("./observerBatching");
observerBatching_1.observerBatching(reactBatchedUpdates_1.unstable_batchedUpdates);
var staticRendering_1 = require("./staticRendering");
exports.isUsingStaticRendering = staticRendering_1.isUsingStaticRendering;
exports.useStaticRendering = staticRendering_1.useStaticRendering;
var observer_1 = require("./observer");
exports.observer = observer_1.observer;
var useObserver_1 = require("./useObserver");
exports.useObserver = useObserver_1.useObserver;
var ObserverComponent_1 = require("./ObserverComponent");
exports.Observer = ObserverComponent_1.Observer;
var utils_1 = require("./utils");
exports.useForceUpdate = utils_1.useForceUpdate;
var useAsObservableSource_1 = require("./useAsObservableSource");
exports.useAsObservableSource = useAsObservableSource_1.useAsObservableSource;
var useLocalStore_1 = require("./useLocalStore");
exports.useLocalStore = useLocalStore_1.useLocalStore;
var useQueuedForceUpdate_1 = require("./useQueuedForceUpdate");
exports.useQueuedForceUpdate = useQueuedForceUpdate_1.useQueuedForceUpdate;
exports.useQueuedForceUpdateBlock = useQueuedForceUpdate_1.useQueuedForceUpdateBlock;
var observerBatching_2 = require("./observerBatching");
exports.isObserverBatched = observerBatching_2.isObserverBatched;
exports.observerBatching = observerBatching_2.observerBatching;
